import React from 'react'
import Lottie from 'react-lottie'
import styled from 'styled-components'
import defaultLoader from './defaultLoader.json'

const Container = styled.div`
	background: rgba(255, 255, 255, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: calc(100vh + 90px);
	position: fixed;
	top: -90px;
	left: 0;
	z-index: 999;
`

const Loader = ({ width, height }) => {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: defaultLoader,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	}
	return (
		<Container>
			<Lottie options={defaultOptions} height={height} width={width} />
		</Container>
	)
}

Loader.defaultProps = {
	width: 100,
	height: 100
}

export default Loader
